import "./icons"
import { init, domReady } from "./utils"
import store from "./store"
import lazyload from "./modules/lazyload"
import primaryNav from "./modules/primary-navigation"
import innerNavigation from "./modules/inner-navigation-sidebar"
import blockquote from "./modules/blockquote"
import renderEvents from "./modules/render-events"
import sliders from "./modules/sliders"

domReady(() => {
  store.dispatch({
    type: "UPDATE_SNIFFER",
    payload: {
      isTouch: !!("ontouchstart" in document.documentElement),
      isIE11:
        navigator.userAgent.indexOf("MSIE") !== -1 ||
        navigator.appVersion.indexOf("Trident/") > -1,
      isEdge: window.navigator.userAgent.indexOf("Edge") > -1,
      passiveListeners: (() => {
        let passiveSupported = false

        try {
          window.addEventListener(
            "test",
            null,
            Object.defineProperty({}, "passive", {
              get: () => (passiveSupported = true),
            }),
          )
        } catch (err) {}

        return passiveSupported
      })(),
    },
  })

  init({
    lazyload,
    primaryNav,
    renderEvents,
    innerNavigation,
    blockquote,
    sliders,
  })
})
