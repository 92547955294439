import store from "../store"
import getListenerOptions from "../get-listener-options"

export default {
  init: function () {
    const t = this
    const mainNav = document.querySelector(".main-nav")

    if (!mainNav) return

    t.idle = true
    t.handleTouchOutside = false
    t.closeOnTouchOutside = t.closeOnTouchOutside.bind(t)
    t.openCloseMenu = t.openCloseMenu.bind(t)

    t.toggleEl = mainNav.previousElementSibling
    t.toggleIcons = [].slice.call(t.toggleEl.querySelectorAll("svg"))
    t.allSubmenus = [].slice.call(document.querySelectorAll(".submenu"))

    t.toggleEl.addEventListener("click", t.openCloseMenu, getListenerOptions())

    if (store.getState().sniffer.isTouch) {
      t.attachSubmenuClicks()
    } else {
      t.navByTabKey([].slice.call(mainNav.querySelectorAll("button, a")))
    }
  },

  navByTabKey: function (navItems) {
    const t = this

    navItems.forEach((navItem) => {
      let mouseDown = false
      navItem.addEventListener(
        "mousedown",
        () => (mouseDown = true),
        getListenerOptions(),
      )
      navItem.addEventListener(
        "mouseup",
        () => (mouseDown = false),
        getListenerOptions(),
      )

      navItem.addEventListener(
        "focus",
        (e) => !mouseDown && t.openCloseSubMenu(e.currentTarget, t.allSubmenus),
        getListenerOptions(),
      )
    })

    window.addEventListener(
      "blur",
      () =>
        t.closeOpenedSubMenus(
          t.allSubmenus.filter((s) => t.isExpanded(s.previousElementSibling)),
        ),
      getListenerOptions(),
    )
  },

  isExpanded: function (t) {
    return t.getAttribute("aria-expanded") == "true"
  },

  toggleExpanded: function (trigger, isOpen) {
    trigger.setAttribute("aria-expanded", isOpen ? "false" : "true")
  },

  openCloseMenu: function () {
    const t = this
    const isOpen = t.isExpanded(t.toggleEl)

    t.toggleExpanded(t.toggleEl, isOpen)

    if (!isOpen) {
      document.addEventListener(
        "touchstart",
        t.closeOnTouchOutside,
        getListenerOptions(),
      )
    }

    t.toggleIcons.forEach((toggleIcon, i) => {
      if ((isOpen && i === 1) || (!isOpen && i === 0)) {
        toggleIcon.classList.add("hidden")
      } else {
        toggleIcon.classList.remove("hidden")
      }
    })

    if (t.idle) {
      t.attachSubmenuClicks()
    }
  },

  openCloseSubMenu: function (trigger, allSubmenus) {
    const t = this
    const subMenuEl = trigger.nextElementSibling

    if (subMenuEl) {
      const isOpen = t.isExpanded(trigger)

      const caretEl = trigger.querySelector("svg")

      const child = subMenuEl.children[0]
      const styles = window.getComputedStyle(child)
      const childRect = child.getBoundingClientRect()
      subMenuEl.style.height = `${
        childRect.height +
        parseInt(styles.marginTop, 10) +
        parseInt(styles.marginBottom, 10)
      }px`

      t.toggleExpanded(trigger, isOpen)

      if (isOpen) {
        caretEl.classList.remove("transfrom", "-rotate-180")

        setTimeout(() => {
          subMenuEl.style.height = 0
        }, 16)
      } else {
        caretEl.classList.add("transfrom", "-rotate-180")
      }
    }

    if (allSubmenus) {
      t.closeOpenedSubMenus(
        allSubmenus.filter(
          (s) =>
            s != subMenuEl &&
            trigger.closest(".submenu") !== s &&
            !trigger.closest(".submenu--deep") &&
            t.isExpanded(s.previousElementSibling),
        ),
      )
    }
  },

  closeOpenedSubMenus: function (opened) {
    const t = this

    opened.forEach((s) => t.openCloseSubMenu(s.previousElementSibling))
    t.handleTouchOutside = opened.length == 0
  },

  closeOnTouchOutside: function (e) {
    const t = this

    if (!e.target.closest(".main-nav")) {
      t.closeOpenedSubMenus(
        t.allSubmenus.filter((s) => t.isExpanded(s.previousElementSibling)),
      )

      document.removeEventListener("touchstart", t.closeOnTouchOutside)

      if (!e.target.closest("[aria-expanded]")) {
        t.openCloseMenu()
      }
    }
  },

  attachSubmenuClicks: function () {
    const t = this

    t.allSubmenus.forEach((subMenuEl) => {
      const trigger = subMenuEl.previousElementSibling

      trigger.addEventListener(
        "click",
        (e) => {
          e.preventDefault()

          t.openCloseSubMenu(trigger, t.allSubmenus)

          if (!t.isExpanded(t.toggleEl)) {
            t.openCloseMenu()
          }

          if (t.handleTouchOutside) {
            document.addEventListener(
              "touchstart",
              t.closeOnTouchOutside,
              getListenerOptions(),
            )
            t.handleTouchOutside = false
          }
        },
        false,
      )

      subMenuEl.addEventListener(
        "transitionend",
        (e) => {
          const target = e.currentTarget
          if (t.isExpanded(target.previousElementSibling)) {
            target.style.height = "auto"
          }
        },
        getListenerOptions(),
      )
    })

    t.idle = false
  },
}
