export default {
  init: function () {
    const t = this
    const innNav = document.querySelector("#inner-navigation-sidebar")

    if (!innNav) return
    
    t.toggleActive()
  },
  
	toggleActive: function() {
    let t = this
    
    document.querySelectorAll( "*[has-child-active]" ).forEach( function( element ) {
      t.performToggle( element.parentNode )
    } )
    
    t.toggle()
  },
  
  toggle: function() {
    let t = this
    
    document.querySelectorAll( "*[data-accordion-target]" ).forEach( function( element ) {
      element.addEventListener( "click", function( event ) {
        if (!event.target.dataset.accordionLink) return
        event.preventDefault()
        t.performToggle( element )
      } )
    } )
  },

  performToggle: function( element ) {
    let t = this

    let accordionElem = element.querySelector('*[data-accordion-child]')
    let accordionElementsAll = document.querySelectorAll('*[data-accordion-child]')
    if (accordionElem.style.height && accordionElem.style.height != '0px') {
      accordionElem.style.height = 0
    }
    else {
      accordionElementsAll.forEach(element => {
        element.style.height = '0px'
      })
      accordionElem.style.height = accordionElem.scrollHeight + 'px'
    }
  },
}
