import { createStore } from "./utils"

const initilState = {
  sniffer: {},
}

const store = createStore((state = initilState, action = {}) => {
  const { payload } = action

  switch (action.type) {
    case "UPDATE_SNIFFER":
      return {
        ...state,
        sniffer: payload,
      }

    default:
      return state
  }
})

export default store
