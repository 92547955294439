import scrollMonitor from "scrollmonitor"

export default class {

    constructor( selector, url, type = "script" ) {
        const self = this

        self.selector = document.querySelector( selector )

        if ( ! self.selector ) throw "Asset Loader: Please specify an existing element selector."
        if ( typeof url !== "string" ) throw "Asset Loader: Please specify a URL."

        self.url = url
        self.type = type

        self.init()
    }

	init() {
        const self = this

        const watcher = scrollMonitor.create( self.selector )

        watcher.enterViewport( () => {
            if ( ! self.loaded && ! self.loading ) {
                let s

                if ( self.type === "style" ) {
                    s = document.createElement( "link" )
                    s.rel = "stylesheet"
                    s.href = self.url
                } else {
                    s = document.createElement( "script" )
                    s.src = self.url
                    s.async = true
                }

                document.body.appendChild( s )

                const onLoadAsset = () => {
                    const self = this
            
                    self.loading = false
                    self.loaded = true
            
                    if ( typeof self.onLoad == "function" ) {
                        self.onLoad()
                    }

                    watcher.destroy()
                }

                s.onload = onLoadAsset
                s.onerror = onLoadAsset

                self.loading = true
                self.loaded = false
            }
        } )
	}
}