import "lazysizes"
import getListenerOptions from "../get-listener-options"

export default {
  init: function () {
    this.onLoaded = this.onLoaded.bind(this)
    this.applyLazysizes()
  },

  onLoaded: function ({ target }) {
    if (target.dataset.placeholderUrl) {
      target.style.backgroundImage = `url("${target.dataset.placeholderUrl}")`
      target.removeAttribute("data-placeholder-url")

      return
    }

    target.classList.add("opacity-100")

    const { parentElement, previousElementSibling } = target
    if (
      parentElement.previousElementSibling &&
      parentElement.previousElementSibling.classList.contains("placeholder")
    ) {
      parentElement.previousElementSibling.classList.add("opacity-0")
    }

    ;["data-src", "data-srcset"].forEach((attr, i) => {
      target.removeAttribute(attr)

      if (
        i === 1 &&
        previousElementSibling &&
        previousElementSibling.nodeName === "SOURCE"
      ) {
        previousElementSibling.removeAttribute(attr)
      }
    })
  },

  applyLazysizes: function () {
    const lazyImages = document.querySelectorAll(".lazyload")

    if (!lazyImages) return

    lazySizes.init()

    document.addEventListener("lazyloaded", this.onLoaded, getListenerOptions())
  },
}
