export default {
  init: function () {
    const t = this
    const wysiwygContent = document.querySelector(".wysiwyg-content")

    if (!wysiwygContent) return
    
    t.blockquoteBackground()
  },
  
	blockquoteBackground: function() {
    let t = this
    
    var blockquoteCss = `.wysiwyg-content blockquote::after { background-image: url('${window.THEME.PATH}/assets/images/blockquote-image.jpeg'); }`,
    head = document.head || document.getElementsByTagName('head')[0],
    style = document.createElement('style');

    head.appendChild(style);

    style.type = 'text/css';
    if (style.styleSheet){
      style.styleSheet.cssText = blockquoteCss;
    } else {
      style.appendChild(document.createTextNode(blockquoteCss));
    }
  },
}
