// import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css/bundle';

import AssetLoader from "./assetLoader"

export default {
  init: function () {
    const t = this
    if( document.querySelectorAll( "[data-slider]" ).length > 0 ) {
      const swiperLoader = new AssetLoader( "[data-slider]", "https://unpkg.com/swiper@8.2.4/swiper-bundle.min.js" )

      swiperLoader.onLoad = () => {
        setTimeout(() => {
          t.sliders = document.querySelectorAll( "[data-slider]" )
          if ( ! t.sliders ) return

          Array.from( t.sliders ).forEach( ( slider, i ) => t.initSlider.call( slider, i ) )
        }, 100)
      }
    }
  },

  initSlider: function (index) {
    const t = this

    t.setAttribute( "id", `slider-${ index }` )

    const options = {
      slidesPerView: 1,
      loop: true,
      autoHeight: true,
      preloadImages: false,
      spaceBetween: 5,
      lazy: {
        loadPrevNext: true
      },
      // modules: [Navigation, Pagination],
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
      },
    }

    new Swiper(`#slider-${index}`, options)
  },
}