export function domReady(fn) {
  if (
    document.readyState === "interactive" ||
    document.readyState === "complete"
  ) {
    return fn()
  }

  document.addEventListener("DOMContentLoaded", fn, false)
}

export function init(activeModules) {
  Object.keys(activeModules).forEach((moduleName) => {
    if (!!activeModules[moduleName].init) {
      activeModules[moduleName].init()
    }
  })
}

export function ajax({ url, data, onSuccess, onError }) {
  const params = new FormData()

  if (typeof data == "object") {
    Object.keys(data).forEach((k) => {
      params.append(k, data[k])
    })
  }

  const xhr = new XMLHttpRequest()

  xhr.onreadystatechange = () => {
    if (xhr.readyState == XMLHttpRequest.DONE) {
      if (xhr.status == 200) {
        if (typeof onSuccess == "function") {
          onSuccess(xhr.response)
        }
      } else {
        if (typeof onError == "function") {
          onError()
        }
      }
    }
  }

  xhr.open("POST", url)
  xhr.setRequestHeader("Cache-Control", "no-cache")

  xhr.send(params)
}

/**
 * A simple Redux-like state management implementation
 *
 * @param {function} reducer The custom reducer.
 * @returns {function} unsubscribe Remove listener.
 */
export function createStore(reducer) {
  let listeners = []
  let currentState = reducer(undefined, {})

  return {
    getState: () => currentState,
    dispatch: (action) => {
      currentState = reducer(currentState, action)

      listeners.forEach((listener) => {
        listener()
      })
    },
    subscribe: (newListener) => {
      listeners.push(newListener)

      const unsubscribe = () => {
        listeners = listeners.filter((l) => l !== newListener)
      }

      return unsubscribe
    },
  }
}
