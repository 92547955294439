import { ajax } from "../utils"

const sectionObserveOffset = 0.25

export default {
  init: function () {
    const t = this

    t.eventsHolder = document.querySelector("[data-events-nonce]")

    if (!t.eventsHolder) return

    const observer = new IntersectionObserver(
      (entries, observer) => {
        if (entries[0].isIntersecting) {
          t.performRequest((resp) => {
            t.render(resp)
          })
          observer.disconnect()
        }
      },
      {
        root: null,
        rootMargin: `${Math.round(
          (window.innerHeight || document.documentElement.clientHeight) *
            sectionObserveOffset,
        )}px 0px`,
        threshold: 0.00001,
      },
    )

    observer.observe(t.eventsHolder.parentElement)
  },

  performRequest: function (then) {
    const t = this

    const maxAttempts = 5
    let currentAttempt = 0

    function performAjaxRequest() {
      ajax({
        url: THEME.AJAX_URL,
        data: {
          action: "get_events_list",
          nonce: t.eventsHolder.dataset.eventsNonce,
        },
        onSuccess: (resp) => {
          if (resp && resp != 0) {
            then(resp);
          } else {
            currentAttempt++;
            if (currentAttempt < maxAttempts) {
              performAjaxRequest();
            } else {
              then('<p class="font-content-sans text-gray-550">No events for the near future.</p>');
            }
          }
        },
        onError: () => {
          currentAttempt++;
          if (currentAttempt < maxAttempts) {
            performAjaxRequest();
          } else {
            then('<p class="font-content-sans text-gray-550">Error fetching events.</p>');
          }
        },
      });
    }

    performAjaxRequest()
  },

  render: function (html) {
    const t = this
    t.eventsHolder.removeChild(t.eventsHolder.querySelector(".events"))
    t.eventsHolder.insertAdjacentHTML("beforeend", html)
  },
}
